<template lang="">
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
            <button type="button" class="ml-2 btn btn-primary mr-1 float-right" v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" @click="showManageRoles(`/organisation_edit/${orgType}/${orgId}#edit-roles`)">Manage Roles</button>
          </template>
          <template v-slot:body>
            <UserList v-if="orgType" :propModuleName="orgType"/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
</b-container>
</template>

<script>
import { socialvue } from "../../config/pluginInit"
import UserList from "../../views/User/UserList.vue"

export default {
  name: "ManageUsers",
  components: {
    UserList
  },
  data () {
    return {
      cvCardTitle: "Team Member Dashboard",
      orgType: null,
      orgId: null,
      propOpenedInModal: false
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    socialvue.index()

    if (this.$route.params.org_type) {
      this.orgType = this.$route.params.org_type
    }

    if (this.$route.params.org_id) {
      this.orgId = this.$route.params.org_id
    }
  },
  methods: {
    /**
     * showManageRoles
     */
    showManageRoles (url) {
      this.$router.push(url)
    }
  }
}
</script>
<style lang="">
</style>
